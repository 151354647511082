import Image1 from "./../../Assets/I1.jpeg"
import Image2 from './../../Assets/I2.jpeg'
import Image3 from './../../Assets/I3.jpeg'
import Image5 from './../../Assets/I5.jpeg'
import Image8 from './../../Assets/I8.jpeg'
import Image9 from './../../Assets/I9.jpeg'
import Image13 from './../../Assets/I13.jpeg'
import Image16 from './../../Assets/I16.jpeg'
import Image25 from './../../Assets/I25.jpeg'
import Image28 from './../../Assets/I28.jpeg'
import Image36 from './../../Assets/I36.jpeg'
import Image38 from './../../Assets/I38.jpeg'
import Image40 from './../../Assets/I40.jpeg'
import Image41 from './../../Assets/I41.jpeg'
import Image43 from './../../Assets/I43.jpeg'
import Image44 from './../../Assets/I44.jpeg'
import Image45 from './../../Assets/I45.jpeg'
import Image48 from './../../Assets/I48.jpeg'
import Image50 from './../../Assets/I50.jpeg'
import Image51 from './../../Assets/I51.jpeg'
import Image52 from './../../Assets/I52.jpeg'
import Image53 from './../../Assets/I53.jpeg'
import Image54 from './../../Assets/I54.jpeg'
import Image55 from './../../Assets/I55.jpeg'
import Image56 from './../../Assets/I56.jpeg'
import Image57 from './../../Assets/I57.jpeg'
import Image58 from './../../Assets/I58.jpeg'
import Image59 from './../../Assets/I59.jpeg'
import Image60 from './../../Assets/I60.jpeg'
import Image61 from './../../Assets/I61.jpeg'
import Image62 from './../../Assets/I62.jpeg'
import Image63 from './../../Assets/I63.jpeg'
import Image64 from './../../Assets/I64.jpeg'
import Image65 from './../../Assets/I65.jpeg'
import Image66 from './../../Assets/I66.jpeg'
import Image67 from './../../Assets/I67.jpeg'
import Image68 from './../../Assets/I68.jpeg'
import Image69 from './../../Assets/I69.jpeg'
import Image70 from './../../Assets/I70.jpeg'
import Image71 from './../../Assets/I71.jpeg'
import Image72 from './../../Assets/I72.jpeg'
import Image73 from './../../Assets/I73.jpeg'
import Image74 from './../../Assets/I74.jfif'
import Image75 from './../../Assets/I75.jfif'
import Table9 from "./../../Assets/t9.jfif"
import Table2 from "./../../Assets/t2.jfif"
import Table3 from "./../../Assets/t3.jfif"
import Table4 from "./../../Assets/t4.jfif"
import Table5 from "./../../Assets/t5.jfif"
import Table6 from "./../../Assets/t6.jfif"
import Table7 from "./../../Assets/t7.jfif"
import Table8 from "./../../Assets/t8.jfif"

const images = [Image1, Image2, Image55, Image3, Image5,  Image8,  Image9, Image13, Image16, Image25, Image28,Image36, Image38, Image40, Image41, Image43, Image44, Image45, Image48, Image50, Image51, Image52, Image53, Image54, Image56, Image57, Image58, Image59, Image60, Image61, Image62, Image63, Image64, Image65, Image66, Image67, Image68, Image69, Image70, Image71, Image72, Image73, Image74, Image75, Table2, Table3, Table4, Table5, Table6, Table7, Table8, Table9]

export default images