export const TITLE = "אז מי אני"
export const DESCRIPTION = `שמי ניסן חי,
מאז ומעולם הייתה בי אהבה לאוכל ואירוח. 
עם ניסיון עשיר של 12 שנים בתחום הקולינריה,
ניהול מטבחים מהמובילים בארץ,
ואהבה טהורה לבישול ולאוכל טוב,
הקמתי את העסק שלי ״שף פרטי ניסן חי״!
שבו אני יחד עם הצוות המוביל שלי,
נעניק לכם חוויה קולינארית ייחודית אצלכם בבית. 
אצלנו נותנים חשיבות רבה לפרטים הקטנים כמו לגדולים,
לכן, בתור לקוחותינו תקבלו את מלוא תשומת הלב.
נשתדל לענות ולהגשים לכם כל בקשה שתחפצו בה. 
עמלנו ועבדנו קשה כדי להגיע למנות המושלמות,
מנות פתיחה מודרניות ויפות עם ניחוחות וטעמים שלא תשכחו,
נתחי בשר מהטובים שיש, טריים ואיכותיים.
וכמובן קינוחי ראווה ופטיפורים לסיום מתוק וטעים. 
בקיצור.. אנו נדאג לכם לאיכות הגבוהה ביותר,
שירות אדיב ומקצועי שילווה אתכם עד לסיום האירוע,
נפתור לכם כל בעיה שתצוץ בדרך.
והכי חשוב נדאג שיהיה טעים וייחודי.
`;
