import Video1 from './../../Assets/V1.mp4'
import Video2 from './../../Assets/V2.mp4'
import Video3 from './../../Assets/V3.mp4'
import Video4 from './../../Assets/V4.mp4'
import Video5 from './../../Assets/V5.mp4'
import Video6 from './../../Assets/V6.mp4'
import Video7 from './../../Assets/V7.mp4'
import Video8 from './../../Assets/V8.mp4'

const videos = [Video1, Video2, Video3, Video4, Video5, Video6, Video7, Video8]

export default videos